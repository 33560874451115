/* global document, IntersectionObserver */
import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'gatsby';
import PostCard from './postcard';

const getIds = items => {
  return items.reduce((acc, item) => {
    if (item.url) {
      // url has a # as first character, remove it to get the raw CSS-id
      let url = item.url.slice(1);
      url = url.replace(/-\d+$/, '');
      acc.push(url);
    }
    if (item.items) {
      acc.push(...getIds(item.items));
    }
    return acc;
  }, []);
};

const useActiveId = itemIds => {
  const [activeId, setActiveId] = useState(`test`);
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setActiveId(entry.target.id);
          }
        });
      },
      { rootMargin: `0% 0% -80% 0%` },
    );
    itemIds.forEach(id => {
      if (!id || !document.getElementById(id)) {
        return;
      }
      observer.observe(document.getElementById(id));
    });
    return () => {
      itemIds.forEach(id => {
        if (!id || !document.getElementById(id)) {
          return;
        }
        observer.unobserve(document.getElementById(id));
      });
    };
  }, [itemIds]);
  return activeId;
};

const renderItems = (items, activeId) => {
  return (
    <ol>
      {items.map(item => {
        let { url } = item;
        url = url.replace(/-\d+$/, '');
        return (
          <li key={url} className="my-2">
            <a
              href={url}
              className="text-sm text-black font-alternate"
              style={{
                fontWeight: activeId === url.slice(1) ? 'bold' : 'normal',
              }}
            >
              {item.value}
            </a>
          </li>
        );
      })}
    </ol>
  );
};

const TableOfContents = ({ items, readon }) => {
  const ref = useRef();
  const [fixed, setFixed] = useState(false);
  const [readonVisible, setReadonVisible] = useState(true);
  // Set the position to fixed if it reaches the top of the screen
  const checkScrollPosition = () => {
    if (ref.current.getBoundingClientRect().y <= 70 && !fixed) {
      setFixed(true);
    }
    if (ref.current.getBoundingClientRect().y > 70) {
      setFixed(false);
    }
  };

  // Listen to scrolling of page
  useEffect(() => {
    document.addEventListener('scroll', checkScrollPosition);
    const box = document.querySelector('.readon');
    const rect = box.getBoundingClientRect();
    console.log(rect)
    console.log(window.innerHeight)
    console.log(document.documentElement)
    if (rect.bottom > (window.innerHeight + 60)) {
      // Bottom is out of viewport
      console.log('true')
      setReadonVisible(false)
    }
    return () => {
      document.removeEventListener('scroll', checkScrollPosition);
    };
  }, []);

  const idList = getIds(items);
  const activeId = useActiveId(idList);
  return (
    <div className="z-40">
      <div ref={ref} />
      <div
        className={`sm:hidden md:block lg:block xl:block toc ${
          fixed ? 'fixed toc-fixed pr-24' : 'lg:relative'
        }`}
      >
        {items.length !== 0 && (
          <p className="font-display text-xl text-grey uppercase">
            Table of Contents
          </p>
        )}
        <div className="border-l-2 mt-2 border-black pl-4">
          {renderItems(items, activeId)}
        </div>
        <div className={`${readonVisible? 'block' : 'hidden'}`}>
        <div className="font-display text-xl text-grey uppercase mt-4">
          READ ON
        </div>
        <Link class="readon" to={`/blog/${readon.to}`}>
          <PostCard
            img={readon.image}
            title={readon.title}
            subtitle={readon.date}
            text={readon.description}
          />
        </Link>
        </div>
      </div>
    </div>
  );
};

export default TableOfContents;
