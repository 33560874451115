/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Slugger from 'github-slugger';
import Img from 'gatsby-image';
import SEO from '../components/seo';
import Menu from '../components/navbar/Menu';
import Footer from '../components/footer';
import HighlightedText from '../components/highlightedtext';
import Linkto from '../components/linkto';

// import BlogLayout from '../components/BlogLayout';
// import BlogSEO from '../components/blogseo';
// import PostHeading from '../components/PostHeading';
// import Info from '../components/Info';
// import Heading from '../components/BlogArticleHeading';
// import Danger from '../components/Danger';
// import Code from '../components/Code';
// import Gif from '../components/Gif';
import TableOfContents from '../components/tableofcontents';
import '../style/blog.css';
import NewsletterBlog from '../components/newsletterblog';

const shortcodes = {
  Link,
  HighlightedText,
  Linkto,
  h1: props => (
    <h1 {...props} className="text-3xl font-display uppercase my-4" />
  ),
  h2: props => (
    <h2
      {...props}
      className="text-2xl font-display uppercase pt-16 -mt-12 mb-4"
    />
  ),
  h3: props => (
    <h3 {...props} className="text-xl font-display uppercase my-4" />
  ),
  p: props => <p {...props} className="font-alternate" />,
  ul: props => <ul {...props} className="unordered-list" />,
  ol: props => <ol {...props} className="italic" />,
  a: props => (
    <a
      {...props}
      target="_blank"
      rel="noreferrer noopener"
      className={`text-primary-400 font-semibold 
        border-b-2 border-transparent border-solid
        transition-all duration-300 ease-in-out
        hover:border-primary-400`}
    />
  ),
};

export const query = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        slug
        date
        references
      }
      body
      headings(depth: h2) {
        value
      }
    }
    allMdx(
      filter: { fields: { draft: { eq: false } } } # add
    ) {
      edges {
        node {
          frontmatter {
            title
            date
            slug
            description
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

const slugger = new Slugger();

const Blog = ({ data }) => {
  // console.log(data)
  const post = data.mdx;
  const readon = data.allMdx.edges.filter(
    item => item.node.frontmatter.slug !== post.frontmatter.slug,
  )[0].node;
  post.headings.forEach(item => {
    const slug = `#${slugger.slug(item.value)}`;
    // eslint-disable-next-line no-param-reassign
    item.url = slug;
  });
  // const authorImg =
  //  post.frontmatter?.author?.img?.childImageSharp?.fluid || null;

  // console.log(post);

  return (
    <div className="flex w-screen overflow-x-hidden z-50 relative">
      <SEO
        // title="KODAI APP | SampleBlog"
        // description="Transcribe your audio recordings into midi files and music sheets using state of the art AI technology. Focus on your creative song-writing flow by saving time on music notation."
        // url="/blog/sampleblog"
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        url={post.frontmatter.slug}
        blogImage={post.frontmatter.image}
      />
      <div className="flex-col flex items-center relative z-50 relative">
        <Menu blog home={false} />
        <div className="flex w-screen bg-salmon flew-row sm:px-8 md:px-24 lg:px-24 xl:px-24 pt-32 pb-24 relative z-40">
          <div className="sm:w-full md:w-3/4 lg:w-3/4 xl:w-3/4 flex flex-col sm:pr-0 md:pr-24 lg:pr-24 xl:pr-24">
            <Img
              fluid={post.frontmatter.image.childImageSharp.fluid}
              className="sm:h-64 lg:newsletter w-auto"
            />
            <div>
              <div className="font-display text-4xl uppercase pt-8">
                {post.frontmatter.title}
              </div>
              <div className="font-alternate text-lg py-8">
                {post.frontmatter.date}
              </div>
              <article className="sm:w-full md:w-3/4 lg:w-3/4 xl:w-3/4  blog-post">
                <MDXProvider components={shortcodes}>
                  <MDXRenderer>{post.body}</MDXRenderer>
                </MDXProvider>
              </article>
              {post.frontmatter.references && (
                <p className="mt-12 mb-2 font-bold italic font-alternate">
                  References
                </p>
              )}
              {post.frontmatter.references && (
                <MDXProvider components={shortcodes}>
                  <MDXRenderer>{post.frontmatter.references}</MDXRenderer>
                </MDXProvider>
              )}
            </div>
          </div>
          <div className="sm:hidden md:block w-1/4 border-l border-black pl-16 z-40 relative">
            {/* <NewsletterBlog /> */}
            <TableOfContents
              items={post.headings}
              readon={{
                to: readon.frontmatter.slug,
                image: readon.frontmatter.image.childImageSharp.fluid,
                title: readon.frontmatter.title,
                subtitle: readon.frontmatter.date,
                text: readon.frontmatter.description,
              }}
            />
          </div>
        </div>
        {/* <div className="sm:block md:hidden">
          <NewsletterBlog />
            </div> */}
        <Footer />
      </div>
    </div>
  );
};

export default Blog;
