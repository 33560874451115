/* eslint-disable react/no-danger */
import React from 'react';

const HighlightedText = ({ children }) => {
  return (
    <div className="flex flex-row h-auto my-4">
      <div className="h-auto border-l-2 mr-2 rounded" />
      <div className="h-auto border-l-2 mr-2 rounded" />
      <div className="h-auto border-l-2 mr-12 rounded " />
      <div className="italic text-3xl font-alternates">{children}</div>
    </div>
  );
};

export default HighlightedText;
